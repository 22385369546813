<template>
  <div class="container">
    <div class="top   ">
      <div class="header_wrap box_sizing flex_box flex_align_center">
        <div class="top_logo">
          <img src="../assets/images/logo_min.png" alt="" />
        </div>
        <div class="box_sizing flex_box flex_align_center" style="margin-right: 10px;">
          <div class="question_flex_toplist flex_1">
            <div class="question_toplist_select top_list2 box_sizing flex_box flex_align_center flex_justify_between">
              <el-select v-model="typeId" placeholder="请选择" style="width: 120px !important;">
                <el-option-group v-for="group in typeList" :key="group.label" :label="group.label">
                  <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-option-group>
              </el-select>
              <!-- <div class="question_toplist_sanjiao"></div> -->
            </div>
          </div>
        </div>
        <div class="search_top box_sizing flex_box flex_align_center">
          <div class="search_input flex_1">
            <input type="text" v-model="text" placeholder="输入关键词搜索" @blur="blurSearch" @focus="focusSearch" />
          </div>
          <div class="search_icon box_sizing" @click="jumpSearch">
            <img src="../assets/images/search_icon.png" alt="" />
          </div>
        </div>
        <el-card class="search_card" v-if="isSearchCard">
          <div class="card_top">
            <div>搜索历史</div>
            <div class="card_clear" @mousedown="clearSearch">清空历史记录</div>
          </div>

          <div class="card_log">
            <div v-for="(item, index) in searchLog" :key="index">
              <div class="card_text" @mousedown="text = item">
                {{ item }}
              </div>
            </div>
          </div>
        </el-card>

        <div class="user_box flex_box flex_align_center" v-if="isLogin">
          <div class="user_img">
            <div class="user_icon">
              <img :src="avatar" alt="" />
            </div>
            <!-- <div class="user_vip">
                        <img src="../assets/images/vip_icon.png" alt="">
                    </div> -->
          </div>
          <div class="flex_box flex_diection" style="width: 60px;">
            <div class="user_name">{{ nickname }}</div>
            <!-- <div class="user_vip_time">vip到期：{{time}}</div> -->
          </div>
          <div class="flex_box flex_diection" style="position: relative;" @click="is_show = !is_show">
            <div class="pratice_type">我的数据</div>
            <div class="data_cont" v-show="is_show">
              <div class="right_list box_sizing flex_box flex_align_center justify-start" v-for="(item, index) in list"
                :key="'right' + index" @click="goPath(item.id)">
                <div class="right_list_icon">
                  <img :src="item.image" alt="" />
                </div>
                <div class="right_list_title">{{ item.title }}</div>
                <div class="right_list_num">{{ item.num }}</div>
              </div>
            </div>
          </div>
          <div class="flex_box flex_diection" @click="centerDialogVisible=true">
            <div class="pratice_type">{{ pratice_type }}</div>
            <!-- <div class="user_vip_time">vip到期：{{time}}</div> -->
          </div>
        </div>
        <div class="flex_box flex_align_center" v-else @click="loginStatus = true">
          <div class="user_img">
            <div class="user_icon">
              <img :src="userImage" alt="" />
            </div>
          </div>
          <div class="user_login_txt">立即登录</div>
          <!-- <div class="user_login_txt">立即</div> -->
        </div>
      </div>
    </div>

    <!-- 微信登录 -->
    <div class="login_wrap now" v-if="loginWx">
      <div class="login_wx_top_box flex_box flex_justify_center box_sizing flex_align_center flex_diection">
        <div class="login_close" @click="loginWx = false">
          <img src="../assets/images/login_close.png" alt="" />
        </div>
        <div class="login_wx_title">扫码登录</div>
        <div class="login_wx_img">
          <img src="../assets/images/wx_img.png" alt="" />
        </div>
        <div class="login_wx_txt">微信扫一扫即可登录</div>
      </div>
    </div>

    <!-- 手机号登录 -->
    <div class="login_wrap" v-if="loginStatus">
      <div class="login_box box_sizing flex_box flex_diection">
        <div class="login_close" @click="loginStatus = false">
          <img src="../assets/images/login_close.png" alt="" />
        </div>
        <template v-if="loginType=='password'">
          <div class="login_top">
            <div class="login_top_item login_top_active">密码登录</div>
          </div>
          <div class="login_list box_sizing flex_box">
            <div class="login_icon">
              <img src="../assets/images/login1.png" alt="" />
            </div>
            <div class="login_input flex_1">
              <input type="text" placeholder="请输入邮箱或手机号" v-model="account" />
            </div>
          </div>
          <div class="login_list box_sizing flex_box">
            <div class="login_icon">
              <img src="../assets/images/login2.png" alt="" />
            </div>
            <div class="login_input flex_1">
              <input type="password" placeholder="请输入密码" v-model="password" />
            </div>
          </div>
          <div class="login_change">
            <div @click="loginType='code'">验证码登录</div>
            <div @click="loginStatus=false;showForget=true;">忘记密码？</div>
          </div>
          <div class="login_btn hand_change" @click="_accountLogin">登录</div>
        </template>
        <template v-else>
          <div class="login_top">
            <div class="login_top_item" :class="{'login_top_active':codeType=='mobile'}" @click="codeType='mobile'">
              手机验证码登录</div>
            <div class="login_top_item" :class="{'login_top_active':codeType=='email'}" @click="codeType='email'">
              邮箱验证码登录</div>
          </div>
          <template v-if="codeType=='email'">
            <div class="login_list box_sizing flex_box">
              <div class="login_icon">
                <img src="../assets/images/login1.png" alt="" />
              </div>
              <div class="login_input flex_1">
                <input type="email" placeholder="请输入邮箱" v-model="email" />
              </div>
            </div>
          </template>
          <template v-else>
            <div class="login_list box_sizing flex_box">
              <div class="login_icon">
                <img src="../assets/images/login1.png" alt="" />
              </div>
              <div class="login_input flex_1">
                <input type="number" maxlength="11" placeholder="请输入手机号" v-model="mobile" />
              </div>
            </div>
          </template>
          <div class="login_list box_sizing flex_box">
            <div class="login_icon">
              <img src="../assets/images/login2.png" alt="" />
            </div>
            <div class="login_input flex_1">
              <input type="number" placeholder="请输入验证码" v-model="code" />
            </div>
            <div class="login_get_code now" v-if="status">重发({{ sec }})</div>
            <div class="login_get_code hand_change" v-else @click="getCode('login')">获取验证码</div>
          </div>
          <div class="login_change" @click="loginType='password'">密码登录</div>
          <div class="login_btn hand_change" @click="login">登录</div>
        </template>

        <div class="login_wx_box flex_box flex_align_center flex_diection">
          <div class="login_wx hand_change" @click="getLoginWx">
            <img src="../assets/images/login_wx.png" alt="" />
          </div>
          <div class="login_wx_txt hand_change">微信二维码登录</div>
        </div>
      </div>
    </div>
    <!-- 找回密码-->
    <div class="login_wrap" v-if="showForget">
      <div class="login_box box_sizing flex_box flex_diection">
        <div class="login_close" @click="showForget = false">
          <img src="../assets/images/login_close.png" alt="" />
        </div>
        <div class="login_top">
          <div class="login_top_item" :class="{'login_top_active':codeType=='mobile'}" @click="codeType='mobile'">手机验证
          </div>
          <div class="login_top_item" :class="{'login_top_active':codeType=='email'}" @click="codeType='email'">邮箱验证
          </div>
        </div>
        <template v-if="codeType=='email'">
          <div class="login_list box_sizing flex_box">
            <div class="login_icon">
              <img src="../assets/images/login1.png" alt="" />
            </div>
            <div class="login_input flex_1">
              <input type="email" placeholder="请输入邮箱" v-model="email" />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="login_list box_sizing flex_box">
            <div class="login_icon">
              <img src="../assets/images/login1.png" alt="" />
            </div>
            <div class="login_input flex_1">
              <input type="number" maxlength="11" placeholder="请输入手机号" v-model="mobile" />
            </div>
          </div>
        </template>
        <div class="login_list box_sizing flex_box">
          <div class="login_icon">
            <img src="../assets/images/login2.png" alt="" />
          </div>
          <div class="login_input flex_1">
            <input type="number" placeholder="请输入验证码" v-model="code" />
          </div>
          <div class="login_get_code now" v-if="status">重发({{ sec }})</div>
          <div class="login_get_code hand_change" v-else @click="getCode('resetpwd')">获取验证码</div>
        </div>
        <div class="login_list box_sizing flex_box">
          <div class="login_icon">
            <img src="../assets/images/login2.png" alt="" />
          </div>
          <div class="login_input flex_1">
            <input type="password" placeholder="请输入新密码" v-model="password" />
          </div>
        </div>
        <div class="login_btn hand_change" @click="resetpwd">重置密码</div>
      </div>
    </div>
    <!-- 设置密码-->
    <div class="login_wrap" v-if="pwdStatus">
      <div class="login_box box_sizing flex_box flex_diection">
        <div class="login_close" @click="pwdStatus = false">
          <img src="../assets/images/login_close.png" alt="" />
        </div>
        <div class="login_top">设置密码</div>
        <div class="login_list box_sizing flex_box">
          <div class="login_icon">
            <img src="../assets/images/login2.png" alt="" />
          </div>
          <div class="login_input flex_1">
            <input type="password" placeholder="请输入密码" v-model="password" />
          </div>
        </div>
        <div class="login_list box_sizing flex_box">
          <div class="login_icon">
            <img src="../assets/images/login2.png" alt="" />
          </div>
          <div class="login_input flex_1">
            <input type="password" placeholder="请输入确认密码" v-model="repassword" />
          </div>
        </div>
        <div class="login_btn hand_change" @click="savepwd">保存</div>
      </div>
    </div>
    <!-- 绑定微信 -->
    <div class="login_wrap" v-if="wxAuthStatus">
      <div class="login_box box_sizing flex_box flex_diection">
        <div class="login_close" @click="loginStatus = false">
          <img src="../assets/images/login_close.png" alt="" />
        </div>
        <div class="login_top">微信绑定手机号</div>
        <div class="login_list box_sizing flex_box">
          <div class="login_icon">
            <img src="../assets/images/login1.png" alt="" />
          </div>
          <div class="login_input flex_1">
            <input type="number" maxlength="11" placeholder="请输入手机号" v-model="mobile" />
          </div>
        </div>
        <div class="login_list box_sizing flex_box">
          <div class="login_icon">
            <img src="../assets/images/login2.png" alt="" />
          </div>
          <div class="login_input flex_1">
            <input type="number" placeholder="请输入验证码" v-model="code" />
          </div>
          <div class="login_get_code now" v-if="status">重发({{ sec }})</div>
          <div class="login_get_code" v-else @click="getCode('login')">获取验证码</div>
        </div>
        <div class="login_btn" @click="login">登录</div>
        <div class="login_wx_box flex_box flex_align_center flex_diection">
          <div class="login_wx" @click="getLoginWx">
            <img src="../assets/images/login_wx.png" alt="" />
          </div>
          <div class="login_wx_txt">微信二维码登录</div>
        </div>
      </div>
    </div>
    <div style="width: 100%;height: 120px"></div>
    <el-dialog title="请选择你的练习类型" :visible.sync="centerDialogVisible" width="520px" center>
      <div style="font-size: 16px;font-weight: bold;">
        PTE Academic / UKVI:
      </div>
      <div>
        用于全球大学申请，澳大利亚、新西兰的移民或工作等各类签证申请，英国工作或学生签证申请
      </div>
      <div style="font-size: 16px;font-weight: bold;margin-top: 30px;">
        PTE Core:
      </div>
      <div>用于加拿大移民或工作签证申请</div>
      <div class="dialog-body">
        <el-button class="btn_change" type="primary" @click="chooseType('first')">PTE Academic / UKVI</el-button>
        <el-button class="btn_change" type="primary" @click="chooseType('second')">PTE Core</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script src="https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>
<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters
  } from "vuex";
  var _this;
  export default {
    name: "headers",
    data() {
      return {
        showForget: false,
        wxAuthStatus: false,
        pwdStatus: false,
        status: false,
        loginStatus: true,
        codeType: 'mobile',
        loginType: 'code',
        loginWx: false,
        isLogin: false,
        isSearchCard: false,
        text: "",
        time: "2020.09.22",
        sec: 0,
        typeId: "",
        typeList: [],
        account: "",
        password: "",
        repassword: "",
        mobile: "",
        email: "",
        code: "",
        thirdid: "",
        userImage: "https://yijishen.qiniu.bronet.cn/uploads/20201116/FpUmbPlH8Obr07XUqla73aVW1sm9.png",
        userImg: require("../assets/images/user_img.png"),
        reqParams: {
          appid: "wx528aba34317dbe2f",
          scope: "snsapi_login",
          redirect_uri: encodeURI(window.location.href),
          // redirect_uri:'https://yijishen.tvue.brofirst.cn',

          state: "123",
          login_type: "jssdk",
          self_redirect: false,
          myWindow: "",
        },
        centerDialogVisible: false,
        pratice_type: localStorage.getItem('activeName') && localStorage.getItem('activeName') == 'second' ?
          'PTE Core' : 'PTE A',
        searchLog: [],
        is_show: false,
        list: [{
            image: require("../assets/images/right0.png"),
            title: "练习题库",
            id: 1,
            num: 0,
          },
          {
            image: require("../assets/images/right1.png"),
            title: "学习时长",
            id: 2,
            num: 0,
          },
          {
            image: require("../assets/images/right2.png"),
            title: "收听课程",
            id: 3,
            num: 0,
          },
          {
            image: require("../assets/images/right3.png"),
            title: "学习新词",
            id: 4,
            num: 0,
          },
        ],
        study_info: {
          totalTime: 0, //总练习时长
          todayTime: 0, //今日练习时长
          timeData: {
            //时长数据
            speaking: 0,
            listening: 0,
            writing: 0,
            reading: 0,
          },
          totalNumber: 0, //总练习条数
          todayNumber: 0, //今日练习条数
          numberData: {
            //条数数据
            speaking: 0,
            listening: 0,
            writing: 0,
            reading: 0,
          },
          bookNumber: 0, //学习新词
          courseNumber: 0, //购买课程
        },
      };
    },
    computed: {
      ...mapState(["nickname", "avatar", "choose_type"]),
    },
    activated() {
      _this = this;
      console.log('created1');
      // if (localStorage.getItem("token")) {
      // 	_this.isLogin = true;
      // 	_this.getUser();
      //     _this.loginStatus = false
      // } else {
      // 	var code = _this.$route.query.code;
      // 	console.log('999999999999999999999999999999999999', code)
      // 	if (code) {
      // 		_this.$axios
      // 			.post("wechat/loginCallback", {
      // 				code: code
      // 			})
      // 			.then((res) => {
      // 				if (res.data.thirdid) {
      // 					_this.thirdid = res.data.thirdid;
      // 					_this.wxAuthStatus = true;
      // 					// _this.$router.replace({ path: '/WxAuth?thirdid=' + res.data.thirdid, replace: true });
      // 				} else {
      // 					console.log(res.data.userinfo, "登录");
      // 					localStorage.setItem("token", res.data.userinfo.token);
      // 					_this.getUser();
      // 					// _this.$router.push("/");
      // 				}
      // 			})
      // 			.catch((err) => {
      // 				_this.$message.error(err.msg);
      // 			});
      // 	}
      // }

      // _this.text = _this.$route.query.keyword;
    },
    created() {
      _this = this;
      console.log('created');
      _this.typeId = _this.$route.query.typeId ? parseInt(_this.$route.query.typeId) : "";
      _this.typelist();
      if (localStorage.getItem("token")) {
        _this.isLogin = true;
        _this.getUser();
        _this.getStudyTime();
        _this.loginStatus = false
      } else {
        var code = _this.$route.query.code;
        console.log('999999999999999999999999999999999999', Array.isArray(code) ? code[code.length - 1] : code)
        if (code) {
          _this.$axios
            .post("wechat/loginCallback", {
              code: Array.isArray(code) ? code[code.length - 1] : code
            })
            .then((res) => {
              if (res.data.thirdid) {
                _this.thirdid = res.data.thirdid;
                _this.wxAuthStatus = true;
                // _this.$router.replace({ path: '/WxAuth?thirdid=' + res.data.thirdid, replace: true });
              } else {
                console.log(res.data.userinfo, "登录");
                localStorage.setItem("token", res.data.userinfo.token);
                // _this.loginStatus = false
                _this.getUser();
                _this.$router.push("/");
                location.reload()
              }
            })
            .catch((err) => {
              _this.$message.error(err.msg);
            });
        }
      }
      _this.text = _this.$route.query.keyword;

    },
    methods: {
      ...mapActions(["userinfo"]),

      chooseType(activeName) {
        localStorage.setItem('activeName', activeName);
        _this.pratice_type = activeName == 'first' ? 'PTE A' : 'PTE Core';
        _this.centerDialogVisible = false
        _this.$emit('change', activeName);
      },
      // 获取微信登录二维码
      getLoginWx() {
        (window.location.href =
          "https://open.weixin.qq.com/connect/qrconnect?appid=" +
          _this.reqParams.appid +
          "&scope=" +
          _this.reqParams.scope +
          "&redirect_uri=" +
          _this.reqParams.redirect_uri +
          "&state=" +
          _this.reqParams.state +
          "#wechat_redirect"),
        "微信登录",
        "width=540,height=540";
      },
      // 获取题型列表
      typelist() {
        var params = {};
        _this.$axios
          .post("exercise.topic_type/getTypeList2", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              res.data.forEach((item, index) => {});
              _this.typeList = res.data;
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

      // 获取用户信息
      getUser() {
        _this.$axios
          .get("user/userInfo", {})
          .then((res) => {
            if (res.code == 1) {
              let userInfo = {
                nickname: res.data.nickname,
                avatar: res.data.avatar,
                choose_type: res.data.choose_type
              };
              localStorage.setItem('choose_type', res.data.choose_type)
              _this.userinfo(userInfo);
              _this.$forceUpdate();
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            _this.$message.error(err.msg);
          });
      },

      //搜索获取焦点
      focusSearch() {
        this.getSearchLog()
        this.isSearchCard = true;
      },

      //搜索失去焦点
      blurSearch() {
        this.isSearchCard = false;
      },

      getSearchLog() {
        _this.$axios
          .post("keyword_log/getKeyword", {})
          .then((res) => {
            if (res.code == 1) {
              _this.searchLog = res.data;
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

      clearSearch() {
        _this.$axios
          .post("keyword_log/delKeyword", {})
          .then((res) => {
            if (res.code == 1) {
              _this.searchLog = [];
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },

      // 跳转搜索结果页
      jumpSearch() {
        if (_this.$route.path == '/questionSearchList') {
          _this.$router.push({
            query: {
              ..._this.$route.query,
              keyword: _this.text,
              typeId: _this.typeId
            }
          })

          _this.$parent.getParams()
        } else {
          _this.$router.push({
            path: "/questionSearchList",
            query: {
              keyword: _this.text,
              typeId: _this.typeId
            },
            replace: true
          });
        }

      },
      //账号登录
      _accountLogin() {
        if (_this.account == "") {
          _this.$message.error("请输入邮箱或手机号");
          return;
        } else if (_this.password == "") {
          _this.$message.error("请输入密码");
          return;
        }
        let params = {
          account: _this.account,
          password: _this.password,
        }
        _this.$axios
          .get('user/login', {
            params
          })
          .then((res) => {
            if (res.code == 1) {
              localStorage.setItem("token", res.data.userinfo.token);
              _this.loginStatus = false;
              _this.wxAuthStatus = false;
              setTimeout(() => {
                _this.getUser();
                _this.isLogin = true;
                location.reload()
              }, 100);
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      },
      // 登录
      login() {
        let params = {
          code: _this.code,
          thirdid: _this.thirdid
        }

        if (this.codeType == 'email') {
          var regular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (_this.email == "") {
            _this.$message.error("请输入邮箱");
            return;
          } else if (!regular.test(_this.email)) {
            _this.$message.error("请输入正确的邮箱");
            return;
          }
          params.email = _this.email;
          var url = "user/emaillogin";
        } else {
          var regular = /^1\d{10}$/;
          if (_this.mobile == "") {
            _this.$message.error("请输入手机号");
            return;
          } else if (!regular.test(_this.mobile)) {
            _this.$message.error("请输入正确的手机号");
            return;
          }
          params.mobile = _this.mobile;
          var url = "user/mobilelogin";
        }
        _this.$axios
          .get(url, {
            params
          })
          .then((res) => {
            if (res.code == 1) {
              localStorage.setItem("token", res.data.userinfo.token);
              _this.loginStatus = false;
              _this.wxAuthStatus = false;
              setTimeout(() => {
                _this.getUser();
                _this.isLogin = true;
                if (!res.data.userinfo.password) {
                  _this.pwdStatus = true;
                }else{
                  location.reload()
                }
              }, 100);
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      },
      savepwd() {
        if (_this.password == "") {
          _this.$message.error("请输入密码");
          return;
        } else if (_this.password != _this.repassword) {
          _this.$message.error("两次密码输入不一致");
          return;
        }
        let params = {
          newpassword: _this.password
        }
        _this.$axios
          .get('user/setpwd', {
            params
          })
          .then((res) => {
            if (res.code == 1) {
              _this.password = '';
              _this.repassword = '';
              _this.pwdStatus = false;
              // location.reload()
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      },
      // 登录
      resetpwd() {
        let params = {
          type: _this.codeType,
          captcha: _this.code,
          newpassword: _this.password
        }

        if (this.codeType == 'email') {
          var regular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (_this.email == "") {
            _this.$message.error("请输入邮箱");
            return;
          } else if (!regular.test(_this.email)) {
            _this.$message.error("请输入正确的邮箱");
            return;
          }
          params.email = _this.email;
        } else {
          var regular = /^1\d{10}$/;
          if (_this.mobile == "") {
            _this.$message.error("请输入手机号");
            return;
          } else if (!regular.test(_this.mobile)) {
            _this.$message.error("请输入正确的手机号");
            return;
          }
          params.mobile = _this.mobile;
        }
        if (_this.password == "") {
          _this.$message.error("请输入密码");
          return;
        }
        _this.$axios
          .get('user/resetpwd', {
            params
          })
          .then((res) => {
            if (res.code == 1) {
              _this.code = '';
              _this.password = '';
              _this.showForget = false;
              _this.loginStatus = true;
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      },
      // 获取验证码
      getCode(event) {

        if (this.codeType == 'email') {
          var regular = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (_this.email == "") {
            _this.$message.error("请输入邮箱");
            return;
          } else if (!regular.test(_this.email)) {
            _this.$message.error("请输入正确的邮箱");
            return;
          }
          var url = `ems/send?event=${event}&email=` + _this.email;
        } else {
          var regular = /^1\d{10}$/;
          if (_this.mobile == "") {
            _this.$message.error("请输入手机号");
            return;
          } else if (!regular.test(_this.mobile)) {
            _this.$message.error("请输入正确的手机号");
            return;
          }
          var url = "sms/send?mobile=" + _this.mobile;
        }
        _this.status = true;
        _this.$axios
          .get(url, {})
          .then((res) => {
            if (res.code == 1) {
              _this.$message.success("发送成功");
              var num = 60;
              var timer = setInterval(function() {
                num--;
                if (num <= 0) {
                  clearInterval(timer);
                  _this.status = false;
                } else {
                  _this.status = true;
                  _this.sec = num;
                }
              }, 1000);
            } else {
              _this.status = false;
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            _this.status = false;
            _this.$message.error(err.msg);
          });
      },
      // 获取学习时长
      getStudyTime() {
        this.$axios.post("user/getExerciseLog").then((res) => {
          if (res.code == 1) {
            this.study_info = res.data;
            this.list[0].num = res.data.totalNumber;
            this.list[1].num = res.data.totalTime;
            this.list[2].num = res.data.courseNumber;
            this.list[3].num = res.data.bookNumber;
            console.log(this.list);
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      goPath(id) {
        switch (id) {
          case 1:
            this.$router.push("/practice");
            break;
          case 2:
            this.$router.push("/my");
            break;
          case 3:
            this.$router.push("/myCouse");
            break;
          case 4:
            this.$router.push("/words");
            break;
          default:
            break;
        }
      },
    },
  };
</script>


<style>
  .top {
    width: 100%;
    /* height: 100px; */
    background: rgba(255, 255, 255, 1);
    /* padding: 0 360px; */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .header_wrap {
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    justify-content: space-between;
  }

  .top_logo {
    margin-right: 63px;
  }

  .top_logo img {
    width: 170px;
    height: 65px;
  }

  .search_top {
    width: 500px;
    height: 34px;
    border-radius: 18px;
    padding: 6px 28px;
    background: rgba(242, 242, 242, 1);
    margin: 0 30px 0 0;
  }

  .search_input {
    min-width: 500px;
    height: 100%;
  }

  .search_card {
    position: absolute;
    top: 68px;
    width: 600px;
    margin-left: 348px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  }

  .search_card .card_top {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
  }

  .search_card .card_top .card_clear {
    text-decoration: underline;
    color: #8c9198;
    letter-spacing: 0.6px;
    cursor: pointer;

  }

  .search_card .card_log {
    display: flex;
    flex-wrap: wrap;
  }

  .search_card .card_log .card_text {
    margin: 13px 10px 10px 10px;
    padding: 5px 12px;
    background: #f9f9f9;
    border-radius: 4px;
  }


  .search_icon {
    width: 14px;
    height: 14px;
  }

  .user_img {
    width: 34px;
    height: 34px;
    margin-right: 10px;
    position: relative;
  }

  .user_icon {
    width: 34px;
    height: 34px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    border-radius: 50%;
    overflow: hidden;
  }

  .user_vip {
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 3;
  }

  .user_name {
    font-size: 14px;
    color: rgba(61, 68, 77, 1);
    font-weight: bold;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pratice_type {
    display: flex;
    align-items: center;
    margin-left: 20px;
    border-radius: 32px;
    height: 32px;
    padding: 0px 16px;
    color: #f6704b;
    background-color: transparent;
    border: 1px solid #f6704b;
    font-weight: 500;
    cursor: pointer;
  }

  .user_vip_time {
    font-size: 12px;
    color: rgba(61, 68, 77, 1);
  }

  .user_login_txt {
    font-size: 14px;
    color: rgba(61, 68, 77, 1);
    font-weight: bold;
  }

  .right_wrap_act {
    height: 0 !important;
    padding: 0 !important;
  }

  .right_wrap {
    width: 258px;
    height: 240px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    padding: 50px 0 0;
    position: fixed;
    top: 80px;
    right: 36px;
    box-shadow: 0 3px 12px #ebd0c3;
    z-index: 99;
  }

  .right_top {
    width: 140px;
    height: 34px;
    background: rgba(246, 112, 75, 1);
    border-radius: 4px;
    line-height: 34px;
    text-align: center;
    position: absolute;
    top: -17px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    cursor: pointer;
    user-select: none;
  }

  .right_list {
    width: 100%;
    height: 44px;
    padding: 0 12px;
  }

  .right_list_icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    flex: 0 0 auto;
  }

  .right_list_title {
    font-size: 16px;
    color: rgba(6, 18, 30, 1);
    margin-right: 12px;
    flex: 0 0 auto;
  }

  .right_list_num {
    font-size: 16px;
    color: rgba(246, 112, 75, 1);
    font-weight: 600;
    flex: 0 0 auto;
  }

  .data_cont {
    position: absolute;
    top: 40px;
    background-color: #ffffff;
    width: 210px;
    border-radius: 10px;
  }
</style>
