<template>
  <div class="container">
    <!-- <div class="right_wrap box_sizing" :class="is_show ? '' : 'right_wrap_act'">
      <div class="right_top" @click="is_show = !is_show">我的数据</div>
      <div v-show="is_show">
        <div
          class="right_list box_sizing flex_box flex_align_center justify-start"
          v-for="(item, index) in list"
          :key="'right' + index"
          @click="goPath(item.id)"
        >
          <div class="right_list_icon">
            <img :src="item.image" alt="" />
          </div>
          <div class="right_list_title">{{ item.title }}</div>
          <div class="right_list_num">{{ item.num }}</div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "right",
  data() {
    return {
      is_show: false,
      list: [
        {
          image: require("../assets/images/right0.png"),
          title: "练习题库",
          id: 1,
          num: 0,
        },
        {
          image: require("../assets/images/right1.png"),
          title: "学习时长",
          id: 2,
          num: 0,
        },
        {
          image: require("../assets/images/right2.png"),
          title: "收听课程",
          id: 3,
          num: 0,
        },
        {
          image: require("../assets/images/right3.png"),
          title: "学习新词",
          id: 4,
          num: 0,
        },
      ],
      study_info: {
        totalTime: 0, //总练习时长
        todayTime: 0, //今日练习时长
        timeData: {
          //时长数据
          speaking: 0,
          listening: 0,
          writing: 0,
          reading: 0,
        },
        totalNumber: 0, //总练习条数
        todayNumber: 0, //今日练习条数
        numberData: {
          //条数数据
          speaking: 0,
          listening: 0,
          writing: 0,
          reading: 0,
        },
        bookNumber: 0, //学习新词
        courseNumber: 0, //购买课程
      },
    };
  },
  created() {
    // this.getStudyTime();
  },
  methods: {
    // 获取学习时长
    getStudyTime() {
      this.$axios.post("user/getExerciseLog").then((res) => {
        if (res.code == 1) {
          this.study_info = res.data;
          this.list[0].num = res.data.totalNumber;
          this.list[1].num = res.data.totalTime;
          this.list[2].num = res.data.courseNumber;
          this.list[3].num = res.data.bookNumber;
          console.log(this.list);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    goPath(id) {
      switch (id) {
        case 1:
          this.$router.push("/practice");
          break;
        case 2:
          this.$router.push("/my");
          break;
        case 3:
          this.$router.push("/myCouse");
          break;
        case 4:
          this.$router.push("/words");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
.right_wrap_act {
  height: 0 !important;
  padding: 0 !important;
}
.right_wrap {
  width: 258px;
  height: 240px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  padding: 50px 0 0;
  position: fixed;
  top: 80px;
  right: 36px;
  box-shadow: 0 3px 12px #ebd0c3;
  z-index: 99;
}

.right_top {
  width: 140px;
  height: 34px;
  background: rgba(246, 112, 75, 1);
  border-radius: 4px;
  line-height: 34px;
  text-align: center;
  position: absolute;
  top: -17px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.right_list {
  width: 100%;
  height: 44px;
  padding: 0 12px;
}

.right_list_icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  flex: 0 0 auto;
}

.right_list_title {
  font-size: 16px;
  color: rgba(6, 18, 30, 1);
  margin-right: 12px;
  flex: 0 0 auto;
}

.right_list_num {
  font-size: 16px;
  color: rgba(246, 112, 75, 1);
  font-weight: 600;
  flex: 0 0 auto;
}
</style>








