<template>
  <div class="pte-recorder" :class="currentState">
    <div class="header">Recorded Answer</div>

    <div class="status-container">
      <span class="status-label">Current Status:</span>
      <span class="status-text" :class="statusClass">{{ currentStatus }}</span>
    </div>

    <div class="instruction">{{ statusInstruction }}</div>

    <!-- 进度条 -->
    <div class="progress-container">
      <div class="progress-background">
        <div
          class="progress-active"
          :style="{ width: progress + '%' }"
        ></div>
        <div
          class="progress-thumb"
          :style="{ left: progress + '%' }"
        ></div>
      </div>
      <div class="time-display">
        {{ formattedCurrentTime }} / 0:20
      </div>
    </div>

    <div class="controls">
      <button
        class="control-btn record"
        :disabled="!isRecordEnabled"
        @click="handleRecord"
      >
        Record
      </button>

      <button
        class="control-btn playback"
        :disabled="!isPlaybackEnabled"
        @click="handlePlayback"
      >
        Playback
      </button>

      <button
        class="control-btn stop"
        :disabled="!isStopEnabled"
        @click="handleStop"
      >
        Stop
      </button>
    </div>

    <audio ref="audioPlayer" @ended="handleAudioEnd"></audio>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentState: 'idle',
      mediaRecorder: null,
      audioChunks: [],
      audioUrl: null,
      progress: 0,
      currentTime: 0,
      duration: 20,
      intervalId: null
    }
  },

  computed: {
    currentStatus() {
      const statusMap = {
        idle: 'Ready',
        recording: 'Recording',
        completed: 'Complete',
        playing: 'Playing'
      }
      return statusMap[this.currentState]
    },

    statusInstruction() {
      return {
        idle: 'Click Record to Begin',
        recording: 'Recording',
        completed: 'Complete',
        playing: 'Playing'
      }[this.currentState]
    },

    statusClass() {
      return {
        'status-ready': this.currentState === 'idle',
        'status-recording': this.currentState === 'recording',
        'status-complete': this.currentState === 'completed',
        'status-playing': this.currentState === 'playing'
      }
    },

    isRecordEnabled() {
      return ['idle', 'completed'].includes(this.currentState)
    },

    isPlaybackEnabled() {
      return this.currentState === 'completed'
    },

    isStopEnabled() {
      return ['recording', 'playing'].includes(this.currentState)
    },

    formattedCurrentTime() {
      const secs = Math.floor(this.currentTime % 60)
      return `0:${secs.toString().padStart(2, '0')}`
    }
  },

  methods: {
    async handleRecord() {
      if (this.currentState === 'completed') {
        URL.revokeObjectURL(this.audioUrl)
        this.audioUrl = null
      }

      try {
        this.resetProgress()
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
        this.mediaRecorder = new MediaRecorder(stream)

        this.mediaRecorder.ondataavailable = e => {
          this.audioChunks.push(e.data)
        }

        this.mediaRecorder.onstop = () => {
          const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' })
          this.audioUrl = URL.createObjectURL(audioBlob)
          this.currentState = 'completed'
          this.audioChunks = []
          clearInterval(this.intervalId)
        }

        this.mediaRecorder.start()
        this.currentState = 'recording'

        this.intervalId = setInterval(() => {
          if (this.currentTime >= this.duration) {
            this.handleStop()
            return
          }
          this.currentTime++
          this.progress = Math.min(100, (this.currentTime / this.duration) * 100)
        }, 1000)

      } catch (error) {
        alert('Microphone access required!')
      }
    },

    handleStop() {
      if (this.currentState === 'recording') {
        this.mediaRecorder.stop()
      } else if (this.currentState === 'playing') {
        this.$refs.audioPlayer.pause()
        this.$refs.audioPlayer.currentTime = 0
      }
      clearInterval(this.intervalId)
      this.currentState = 'completed'
    },

    handlePlayback() {
      if (this.audioUrl) {
        this.resetProgress()
        this.$refs.audioPlayer.src = this.audioUrl
        this.$refs.audioPlayer.play()
        this.currentState = 'playing'

        this.intervalId = setInterval(() => {
          if (this.currentTime >= this.duration) {
            this.handleStop()
            return
          }
          this.currentTime = this.$refs.audioPlayer.currentTime
          this.progress = Math.min(100, (this.currentTime / this.duration) * 100)
        }, 200)
      }
    },

    handleAudioEnd() {
      this.currentState = 'completed'
      clearInterval(this.intervalId)
    },

    resetProgress() {
      this.currentTime = 0
      this.progress = 0
      clearInterval(this.intervalId)
    }
  },

  beforeDestroy() {
    clearInterval(this.intervalId)
    if (this.audioUrl) {
      URL.revokeObjectURL(this.audioUrl)
    }
  }
}
</script>

<style scoped>
.pte-recorder {
  width: 480px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.header {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 24px;
}

.status-container {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.status-label {
  font-size: 14px;
  color: #666;
  margin-right: 8px;
}

.status-text {
  font-size: 14px;
  font-weight: 500;
}

.status-ready { color: #666; }
.status-recording { color: #E34D59; }
.status-complete { color: #07C160; }
.status-playing { color: #108EE9; }

.instruction {
  font-size: 14px;
  color: #999;
  margin-bottom: 24px;
  height: 20px;
}

/* 进度条样式 */
.progress-container {
  margin: 20px 0;
  user-select: none;
  pointer-events: none;
}

.progress-background {
  height: 4px;
  background: #ebedf0;
  border-radius: 2px;
  position: relative;
}

.progress-active {
  height: 100%;
  background: #ebedf0;
  border-radius: 2px;
  transition: width 0.3s ease;
}

.pte-recorder.recording .progress-active,
.pte-recorder.playing .progress-active {
  background: currentColor;
}

.progress-thumb {
  position: absolute;
  width: 12px;
  height: 12px;
  background: #ebedf0;
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.3s ease;
}

.pte-recorder.recording .progress-thumb,
.pte-recorder.playing .progress-thumb {
  background: currentColor;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.time-display {
  text-align: center;
  font-size: 12px;
  color: #666;
  margin-top: 8px;
}

/* 按钮样式 */
.controls {
  display: flex;
  gap: 16px;
}

.control-btn {
  flex: 1;
  height: 36px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: #fff;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}

.control-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background: #f5f5f5;
  border-color: #d9d9d9;
  color: #bfbfbf;
}

.record:not(:disabled) {
  background: #07C160;
  border-color: #07C160;
  color: white;
}

.playback:not(:disabled) {
  background: #108EE9;
  border-color: #108EE9;
  color: white;
}

.stop:not(:disabled) {
  background: #E34D59;
  border-color: #E34D59;
  color: white;
}
</style>
