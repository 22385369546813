<template>
    <div class="container">
        <div  class="w box_sizing width_box my_wrap w_exam">

            <div class="banner_detail box_sizing" style="height: 100vh;position: relative;">
                <div style="font-size: 18px;font-weight: 600;margin-bottom: 20px;">{{ name }}</div>
                <div class="listTitle flex_box flex_justify_between flex_align_center box_sizing">
                    <div class="flex_box flex_align_center">
                        <div class="biao" v-if="type < 4">设备检查</div>
                        <div class="biao" v-else>考试介绍</div>
                    </div>
                    <!-- <img src="../assets/images/guanbi.png" alt="" style="width: 16px;"> -->
                </div>
                <div class="flex_align_center flex_box box_sizing produce" v-if="type == 1">
                    <!-- <img src="../assets/images/produce.png" alt=""> -->
                    <div class="produceCon">
                        <!-- <div class="produceTitle box_sizing">考前介绍</div> -->
                        <div class="producedetail box_sizing" v-html="detail.exam_intro">
                            {{detail.exam_intro}}
                        </div>
                    </div>
                </div>
                <div class="flex_align_center flex_box box_sizing produce" v-if="type == 2">
                    <!-- <img src="../assets/images/produce.png" alt=""> -->
                    <div class="produceCon">
                        <!-- <div class="produceTitle box_sizing">考前介绍</div> -->
                        <div class="producedetail box_sizing">
                          <div style="display: flex;" v-html="detail.exam_notice1">
                            {{detail.exam_notice1}}
                          </div>
                        </div>
                        <exam-audio></exam-audio>
                        <div class="producedetail box_sizing" v-html="detail.exam_notice3">
                            {{detail.exam_notice3}}
                        </div>
                    </div>
                </div>
                <div class="flex_align_center flex_box box_sizing produce" v-if="type == 3">
                    <!-- <img src="../assets/images/produce.png" alt=""> -->
                    <div class="produceCon">
                        <!-- <div class="produceTitle box_sizing">考前介绍</div> -->
                        <div class="producedetail box_sizing" v-html="detail.exam_notice2">
                            {{detail.exam_notice2}}
                        </div>
                    </div>
                </div>
                <div class="flex_align_center flex_box box_sizing produce" v-if="type == 4">
                    <!-- <img src="../assets/images/produce.png" alt=""> -->
                    <div class="produceCon">
                        <!-- <div class="produceTitle box_sizing">考前介绍</div> -->
                        <div class="producedetail box_sizing" v-html="detail.exam_notice5">
                            {{detail.exam_notice5}}
                        </div>
                    </div>
                </div>
                <div class="last3 flex_box " v-if="type == 4">
                    <div class="next1 hand_hover" v-if="type > 1" @click="typeChange2">PREVIOUS</div>
                    <div class="next1 hand_hover" @click="toDati">NEXT</div>
                </div>
                <div class="last3 flex_box " v-if="type != 4">
                    <div class="next1 hand_hover" v-if="type > 1" @click="typeChange2">PREVIOUS</div>
                    <div class="next1 hand_hover" @click="typeChange">NEXT</div>
                </div>
                <!-- <div class="button hand_hover" v-else @click="toDati">开始答题</div> -->
            </div>


        </div>

    </div>
</template>

<script>
var _this;
import ExamAudio from "@/components/examAudio";
export default {
    components: {
      ExamAudio
    },
    data() {
        return {
            current:0,
            mokao:[1,1,1,1],
            type: 1,
            detail: {},
            id: '',
            name: ''
        }
    },
    created() {
       _this = this;
       _this.getParams()
       _this.getInfo()
    },
    mounted() {},
    methods: {

        // 获取传递过来的参数
        getParams() {
            _this.id = _this.$route.query.id;
            _this.name = _this.$route.query.name;
        },

        // 下一步
        typeChange() {
            _this.type = _this.type + 1;
        },
        // 上一步
        typeChange2() {
            _this.type = _this.type - 1;
        },

        // 获取靠前信息
        getInfo() {
            var params = {}
            _this.$axios
            .post("exam.user_exam/examConfig", params)
            .then((res) => {
                console.log(res);
                if (res.code == 1) {
                    _this.detail = res.data;
                } else {
                    _this.$message.error(res.msg);
                }
                })
            .catch((err) => {
                console.log(err);
            });
        },

        // 跳转模考答题
        toDati(){
            this.$router.push({
                path: '/mokaoDetail',
                query: {
                    id: _this.id,
                    name: _this.name
                }
            })
        }
    }

}
</script>


<style>
    .w_exam {
      width: 100% !important;
      padding-left: 60px;
      padding-right: 60px;
    }
    /* .my_wrap {
      padding-left: 60px;
      padding-right: 60px;
    } */
    .banner_detail {
        width: 100%;
        padding: 34px 35px;
        border-radius: 20px;
        background: rgba(255, 255, 255, 1);
    }
    .listTitle{
        width: 951px;
        height: auto;
        padding: 11px 20px;
        background: #F9F9F9;
        opacity: 1;

    }
    .biao{
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 600;
        line-height: 30px;
        color: #06121E;
        margin-right: 20px;
    }
    .produce{
        padding: 0;
        /* min-height: 200px; */
    }
    .produce img{
      margin-left: 30px;
        width: auto;
        /* margin-right: 97px; */
    }
   .produceCon{
       text-align: left;
   }
   .produceTitle{
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        line-height: 22px;
        color: #F6704B;
        padding: 20px 0;
        position: relative;
   }
   .produceTitle:after{
       content: '';
       width: 63px;
        height: 1px;
        background: #F08300;
        opacity: 1;
        top: 100%;
        left: 0;
        position: absolute;

   }
   .producedetail{
    /* width: 541px; */
    /* height: 282px; */
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 20px;
    color: #06121E;
    opacity: 1;
    padding-top: 36px;
    /* display: flex; */

   }
   .top_content {
    display: flex;
    justify-content: space-between;
   }
   .headsetright {
    width: 321px;
    height: 223px;
   }
   .button{
    width: 175px;
    height: 40px;
    background: linear-gradient(136deg, #F38572 0%, #ED4D35 100%);
    opacity: 1;
    border-radius: 22px;
    color:#FFFFFF;
    text-align: center;
    line-height: 40px;
   }

   .last3{
        width: auto;
        justify-content: flex-end;
        /* margin-top: 50px; */
        position: absolute;
        bottom : 50px;
        right: 50px;
    }
    .notice1_img {
    }
    .next1{
        width: 78px;
        height: 30px;
        background: #F6704B;
        opacity: 1;
        border-radius: 4px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 30px;
        margin: 0 8px;
    }
</style>
