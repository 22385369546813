<template>
  <div class="container">
    <headers></headers>
    <right></right>
    <scrollpage></scrollpage>
    <word ref="word"></word>

    <div class="w box_sizing width_box my_wrap">
      <div class="left_position">
        <left></left>
      </div>

      <div class="question_list_wrap box_sizing">
        <!-- 提示音 -->
        <audio ref="audio" src="../../assets/yinxiao.mp3"></audio>
        <div class="question_type_box box_sizing flex_box flex_align_center flex_justify_between">
          <navtitle :topic_id="id" title="朗读句子" pinyin="RA" page="questionType1"></navtitle>
        </div>

        <div class="topic_wrap" v-loading="loading" element-loading-text="正在获取AI评分，请稍后...">
          <div class="topic_type_name">{{ detail.name }}</div>
          <div class="topic_type_info box_sizing flex_box flex_align_center">
            <div class="topic_type_info_time">{{ detail.createtime_text }}</div>
            <div class="question_lian_shoucang">
              <img class="question_list_jiangjieimg" v-if="!detail.collect || detail.collect == 1" @click="collowCilck()"
                src="../../assets/images/shoucang1.png" alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 2" @click="collowCilck()" src="../../assets/images/shoucang2.png"
                alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 3" @click="collowCilck()" src="../../assets/images/shoucang3.png"
                alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 4" @click="collowCilck()" src="../../assets/images/shoucang4.png"
                alt="" />
              <img class="question_list_jiangjieimg" v-if="detail.collect == 5" @click="collowCilck()" src="../../assets/images/shoucang5.png"
                alt="" />
              <div class="question_list_sc_box" v-if="fav_show">
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(1)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang1.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(2)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang2.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(3)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang3.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(4)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang4.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="question_list_sc_list box_sizing">
                  <div
                    class="question_list_sc_item box_sizing flex_box flex_align_center flex_justify_center"
                    @click="collectFun(5)"
                  >
                    <img
                      class="question_list_jiangjieimg"
                      src="../../assets/images/shoucang5.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <img src="../../assets/images/type_fenxiang.png" class="topic_type_info_fenxiang" alt=""
              @click="copyDomain()" /> -->
          </div>
          <div class="topic_type_title">{{ detail.describe }}</div>
          <div class=" mp3_tool" v-if="fastMp3Url != '' || slowMp3Url != ''">
            <div style="display: flex">
              <span style="flex: 1" v-if="fastMp3Url != ''">朗读示范(常速)</span>
              <span style="flex: 1" v-if="slowMp3Url != ''">朗读示范(慢速)</span>
            </div>
            <div style="display: flex;margin: 20px 0px">
              <div style="flex: 1" v-if="fastMp3Url != ''">
                <mp3-tool ref="mp3" :src-url="fastMp3Url"></mp3-tool>
              </div>
              <div style="flex: 1" v-if="slowMp3Url != ''">
                <mp3-tool ref="mp3" :src-url="slowMp3Url"></mp3-tool>
              </div>
            </div>
          </div>
          <div class="topic_type_timu_box box_sizing" v-if="topicType == 1">
            <span @click="dianjiClick(item)" v-for="(item, index) in detail.show_text" :key="index"
               v-html="item"></span>
          </div>
          <div class="topic_type_timu_box box_sizing" v-if="topicType == 2">
            <span class="onecentence" :class="index == start_index || index == end_index ? 'word_select_color' : (index>start_index && index<end_index ? 'word_color' : '')" @click="dianjiChoose(item,index)" v-for="(item, index) in detail.show_text" :key="index"
               v-html="item"></span>
          </div>
          <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-if="daojishiTime > 0">
            {{ daojishiTime }}s后开始答题
          </div>
          <div class="dati_time box_sizing flex_box flex_align_center justify_end" v-else>
            答题时间：{{ datiTime }}
          </div>
          <div class="topic_type_answer_box box_sizing" style="min-height: 280px" v-if="daojishiTime >= 0">
            <div class="topic_type_answer_title">Recorded Answer</div>
            <div class="topic_type_tt">Curret Status</div>
            <div id="siri-classic" style="width: 100%; height: 100px"></div>

            <audio-cmponent ref="AudioC" @GetAudioData="GetAudioDataEven" @BeginEven="BeginEvenF" @play="playEvent"
              @notplay="notplayEvent" @audioDuration="audioDuration" @ErrorMsg="ErrorMsg" @ErrorMsg2="ErrorMsg2"></audio-cmponent>
            <div v-if="topicType == 2" style="text-align: center;margin-top: 20px;">点击选择首词和尾词来确定录音部分（至少 10 词）</div>
          </div>
          <div>
            <el-upload :action="uploadSrc" :multiple="false" :on-success="handleChange" :headers="{ token: getToken }">
            </el-upload>
          </div>
          <div class="topic_type_answer_bottom box_sizing flex_box flex_align_center flex_justify_between">
            <div class="topic_type_answer_botleft box_sizing flex_box flex_align_center">
              <div class="topic_type_answer_botbtn" @click="submitTopic">
                完成
              </div>
              <div class="topic_type_answer_botbtn now" @click="chongzuo">
                重做
              </div>
              <div class="topic_type_answer_botbtn now only_one" @click="onlyOne()">
                {{ only_one_text }}
              </div>
            </div>
            <div class="topic_type_answer_botcenter box_sizing flex_box flex_align_center">
              <div class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center" @click="seeDaFen">
                <img src="../../assets/images/dafen_icon.png" class="topic_type_answer_botcenter_img" alt="" />
                <div class="topic_type_answer_botcenter_txt">查看打分</div>
              </div>
              <div class="topic_type_answer_botcenter_list box_sizing flex_box flex_align_center"
                @click="shenQingPiGai">
                <img src="../../assets/images/shenqing_icon.png" class="topic_type_answer_botcenter_img" alt="" />
                <div class="topic_type_answer_botcenter_txt">申请批改</div>
              </div>
            </div>
            <div class="topic_type_answer_botright box_sizing flex_box flex_align_center">
              <div class="topic_type_answer_botbtn tihao" @click="changeTopic('lastTopic')">上一题</div>
              <div class="topic_type_answer_botbtn" @click="changeTopic('nextTopic')">
                下一题
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footers></footers>

    <!-- 弹框 -->
    <div class="model" v-if="status">
      <div class="modelContent box_sizing">
        <div class="title">Read Aloud</div>
        <div class="flex-warp flex_box onebox" v-if="!result_loading">
          <div class="one flex_box flex_align_center box_sizing flex-wrap" v-if="obj.content_score">
            <div class="neirong flex_box">内容</div>
            <div class="fenshu">
              <div>得分：{{ obj.content_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.content_score.full }}分
              </div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.quality_score">
            <div class="neirong flex_box">发音分</div>
            <div class="fenshu">
              <div>得分：{{ obj.quality_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.quality_score.full }}分
              </div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.pte_score">
            <div class="neirong flex_box">流利度</div>
            <div class="fenshu">
              <div>得分：{{ obj.pte_score.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.pte_score.full }}分</div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.format_score">
            <div class="neirong flex_box">格式</div>
            <div class="fenshu">
              <div>得分：{{ obj.format_score.score }}分</div>
              <div style="color: #8c9198">
                满分：{{ obj.format_score.full }}分
              </div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.structure">
            <div class="neirong flex_box">发展结构</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.grammar">
            <div class="neirong flex_box">语法</div>
            <div class="fenshu">
              <div>得分：{{ obj.grammar.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.grammar.full }}分</div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.structure">
            <div class="neirong flex_box">语言范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.structure.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.structure.full }}分</div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.wordScore">
            <div class="neirong flex_box">词汇范围</div>
            <div class="fenshu">
              <div>得分：{{ obj.wordScore.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.wordScore.full }}分</div>
            </div>
          </div>
          <div class="one flex_box flex_align_center box_sizing" v-if="obj.spelling">
            <div class="neirong flex_box">拼写</div>
            <div class="fenshu">
              <div>得分：{{ obj.spelling.score }}分</div>
              <div style="color: #8c9198">满分：{{ obj.spelling.full }}分</div>
            </div>
          </div>
        </div>
        <div class="waiting_result" v-else>等待AI评分中...</div>
        <div class="zuoda" style="font-size: 16px;" v-if="obj.word_data != null">
          <div>{{obj.word_data}}</div>
        </div>
        <div class="zuoda box_sizing">
          <div class="zuodatitle box_sizing">作答内容</div>
          <mp3-tool ref="mp3" :src-url="auFullUrl" @showDuration="getDuration"></mp3-tool>
        </div>
        <div class="zuoda box_sizing" v-if="!result_loading">
          <div class="zuodatitle box_sizing">AI识别</div>
          <div class="ai_box">
            <div class="ai_level_type">
              <div class="ai_level_type_color goodcolor"></div>
              <div>Good</div>
            </div>
            <div class="ai_level_type">
              <div class="ai_level_type_color badcolor"></div>
              <div>Bad</div>
            </div>
            <div class="ai_level_type">
              <div class="ai_level_type_color averagecolor"></div>
              <div>Average</div>
            </div>
          </div>
          <div v-html="obj.ai_result"></div>
        </div>

        <div class="cancel" @click="status = false">
          <img src="../../assets/images/login_close.png" alt="" />
        </div>

        <!-- <div class="ping">
                    <img src="../../assets/images/wenhao.png" alt="">
                    <div>评分细则</div>
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import headers from "@/components/headers";
import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
import "@/css/questionList.css";
import "@/css/questiontype.css";
import "@/css/pagePing.css";
import "@/css/pageNa.css";
import SiriWave from "siriwave";
import AudioCmponent from "@/components/audiocmponent/audiocmponent.vue";
import upload from "element-ui/packages/upload";
import Navtitle from "@/components/navtitle.vue";
import Mp3Tool from "@/components/mp3Tool.vue";
import Word from "@/components/word.vue";

var daojishiBo;
let waitingresult; // 等待结果
var _this;
export default {
  computed: {
    upload() {
      return upload;
    },
    uploadSrc() {
      return this.$global.uploadUrl + "api/common/uploadRecord";
    },
    getToken() {
      return localStorage.getItem("token");
    },
  },
  components: {
    Navtitle,
    AudioCmponent,
    headers,
    footers,
    left,
    right,
    scrollpage,
    Mp3Tool,
    Word
  },
  data() {
    return {
      srcUrl: "",
      loading: false,
      audio_answer_time: null,
      topicExerciseId: null,
      classic: undefined,
      recordings: false, // 标记是否在录音
      recorderData: {},
      id: "",
      detail: {},
      num: 60, // 按住说话时间
      auUrl: "", // 上传录音文件地址
      auFullUrl: "", // 上传录音文件访问地址
      timeLen: 0,
      obj: {},
      huida: false,
      fav_show: false,
      result_loading: false,
      status: false,
      title: "",
      datiTime: "00:00:00",
      daojishiTime: 40,
      audio: null,
      slowMp3Url: "",
      fastMp3Url: "",
      // topicType: 1,
      // only_one_text: "一句话模式",
      topicType: localStorage.getItem('topicType') == 2 ? 2 : 1,
      only_one_text: localStorage.getItem('topicType') == 2 ? "全文模式" : "一句话模式",
      start_index: -1,
      end_index: -1,
      fullscreenLoading: false,
      type: 0
    };
  },
  created() {
    _this = this;
    _this.audio = new Audio();
    _this.getParams();
    _this.daojishiBack();
  },
  destroyed() {
    _this.audio.pause();
    _this.timeLen = _this.timeLen;
    _this.playStatus = false;
    _this.audio.load();
    clearInterval(daojishiBo);
  },
  mounted() {
    _this.init_classic();
    // if(localStorage.getItem('topicType') == 2) {
    //   _this.$refs.AudioC.changeMode(2);
    //   _this.start_index = -1;
    //   _this.end_index = -1;
    //   _this.$refs.AudioC.changeWordIndex(_this.start_index,_this.end_index);
    // }
    // localStorage.removeItem('topicType')
  },
  methods: {
    //选择收藏哪个
    collowCilck() {
      _this.fav_show = !_this.fav_show
      console.log('12313',_this.fav_show);
    },
    // 收藏题目
    collectFun(type) {
      // console.log(i)
      let params = {
        topic_id: _this.detail.id,
        type: type,
      };

      if (localStorage.getItem("token")) {
        _this.$axios
          .post("exercise.topic_collect/collect", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              _this.detail.collect = type;
              _this.fav_show = false;
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },
    init_classic() {
      this.classic = new SiriWave({
        container: document.getElementById("siri-classic"),
        height: 100,
        autostart: false,
        // 显示比例
        ratio: 2,
        // 动画速度
        speed: 0.1,
        // 振幅
        amplitude: 1,
        // 频率(iOS风格)
        frequency: 6,
        // 曲线颜色 (iOS风格)
        color: "#f56207",
        // 覆盖容器
        cover: false,
        // 在画布上步骤数量
        pixelDepth: 0.02,
        // 插入速度
        lerpSpeed: 0.01,
      });
    },
    handleChange(res) {
      clearInterval(daojishiBo);
      console.log("当前的文件", res);
      if (res.code === 1) {
        _this.$message.success("上传成功");
        _this.auUrl = res.data.url;
        _this.srcUrl = _this.$global.uploadUrl + res.data.url;
        console.log("当前的srcUrl", _this.srcUrl);
      }
    },
    getDuration(time) {
      _this.timeLen = time;
    },
    // 录音开始
    BeginEvenF() {
      _this.daojishiTime = 0;
      _this.$refs.audio.play(); //提示音效
      _this.classic.start();
      _this.recordings = true;
      console.log("当前录音状态", _this.recordings);
    },
    // 录音结束
    GetAudioDataEven(res) {
      console.log(`录音结束后返回信息==================>`, res, _this.datiTime);
      _this.classic.stop();
      _this.recorderData = res;
      _this.recordings = false;
      _this.submitUp();
    },
    audioDuration(e){
      console.log(e,'eeeeeeeeeeeeeeee=========================>');
      _this.timeLen = e.duration; // 实际录音时长
      _this.datiTime = _this.formateSeconds(e.show_time); // 展示录音时长
    },
    playEvent() {
      _this.classic.start();
    },
    notplayEvent() {
      _this.classic.stop();
    },
    copyDomain() {
      if (_this.topicExerciseId == null) {
        this.$message.error("请申请批改后复制链接");
        return;
      }
      _this.$copyText(_this.$global.baseUrl + "&id=" + _this.topicExerciseId);
      this.$message.success("地址复制成功");
    },
    // 点击事件
    dianjiClick(item) {
      if(!item || item == '&nbsp;') {return;}
      this.$refs.word.word_text = item;
      this.$refs.word.getWord();
    },
    ErrorMsg(){
      _this.$message.error("开启录音失败，请刷新后重试");
    },
    ErrorMsg2(){
      _this.$message.error("选择的单词数量不能小于10个");
    },
    // 一句话模式点击单词
    dianjiChoose(item,index) {
      console.log('aaaaaaa',item,index);
      if(item == '&nbsp;') return;
      if(_this.start_index >= 0 && _this.end_index >= 0) {
        _this.start_index = index;
        _this.end_index = -1;
      } else {
        if(index == _this.start_index) {
          _this.start_index = -1;
          _this.end_index = -1;
        } else {
          if(_this.start_index >= 0) {
            if(index-_this.start_index < 20) {
              _this.$message.error("选择的单词数量不能小于10个");
            } else {
              _this.end_index = index
            }
          } else {
            _this.start_index = index
          }
        }
      }
      _this.$refs.AudioC.changeWordIndex(_this.start_index,_this.end_index);
    },
    onlyOne() {
      if(_this.topicType == 1) {
        _this.topicType = 2;
        _this.only_one_text = "全文模式";
        _this.$refs.AudioC.changeMode(2);
        _this.start_index = -1;
        _this.end_index = -1;
        _this.$refs.AudioC.changeWordIndex(_this.start_index,_this.end_index);
      } else {
        _this.topicType = 1;
        _this.only_one_text = "一句话模式"
        _this.$refs.AudioC.changeMode(1);
      }
      // localStorage.setItem('topicType',_this.topicType);
      _this.daojishiTime = 40;
      _this.playStatus = false;
      _this.recordings = false;
      _this.$refs.AudioC.resetIsVoice();
      _this.classic.dispose();
      _this.init_classic();
      clearInterval(daojishiBo);
      _this.daojishiBack();
      _this.$refs.AudioC.stopReset();
    },
    // 进入页面倒计时40秒开始录音
    daojishiBack() {
      daojishiBo = setInterval(() => {
        if (_this.daojishiTime <= 0) {
          console.log("倒计时录音状态", _this.recordings);
          if (!_this.recordings && _this.audio_answer_time == null) {
            // _this.$refs.audio.play(); //提示音效
            setTimeout(() => {
              console.log('audioc');
              _this.$refs.AudioC.startRecorder();
            }, 1500);
          }
          clearInterval(daojishiBo);
          let playPromise;
          playPromise = _this.audio.play();
          _this.playStatus = true;
          if (playPromise) {
            playPromise
              .then(() => {
                // 音频加载成功
                // 音频的播放需要耗时
              })
              .catch((e) => {
                // 音频加载失败
                console.error(e);
              });
          }
        } else {
          _this.daojishiTime--;
        }
      }, 1000);
    },
    // 上一题/下一题
    changeTopic(name) {
      var params = {
        topic_id: _this.id,
        type: _this.detail.topic_type_id,
        exercise_status: localStorage.getItem('lianxiId'),
        topic_collect: localStorage.getItem('collectId'), //收藏 之前添加传的
        pinlvId: localStorage.getItem('pinlvId'),
        tags: localStorage.getItem('tag_id'),
        high_type: _this.type,
        topic_lecture_id:
        localStorage.getItem('jiangjieId') == 0 ? undefined : localStorage.getItem('jiangjieId') == 1 ? 1 : 0, //名师讲解id 0=没有讲解
      };
      _this.$axios
        .post("exercise.topic/" + name, params)
        .then((res) => {
          if (res.code == 1) {
            _this.id = res.data.topic_id;
            console.log("当前的返回", res.data.topic_id);
            this.$router.replace({
              path: "questionType1?id=" + res.data.topic_id + "&name=朗读句子&type=" +_this.type,
            });
            location.reload();
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          _this.$message.error(err.msg);
        });
    },
    // 重做
    chongzuo(reload = null) {
      location.reload();
      return;
    },
    // 申请批改
    shenQingPiGai() {
      if (_this.huida) {
        var params = {
          topic_exercise_id: _this.topicExerciseId,
        };
        _this.$axios
          .post("exercise.topic_exercise/submit", params)
          .then((res) => {
            if (res.code == 1) {
              _this.$message.success("您已提交申请");
            } else {
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
            _this.$message.error(err.msg);
          });
      } else {
        _this.$message.error("请先点击完成以提交答题");
      }
    },
    // 查看打分
    seeDaFen() {
      if (_this.huida) {
        _this.status = true;
      } else {
        _this.$message.error("请先点击完成以提交答题");
      }
    },
    // 点击完成
    submitTopic() {
      if(_this.huida) {
        _this.status = true;
        return;
      }
      console.log("当前录音状态", _this.recordings);
      // nprogress.start();
      _this.loading = true;
      if (_this.recordings) {
        _this.$message.error("录音中,禁止操作");
        return;
      }
      let params = {
        topic_id: _this.id,
        content: _this.auUrl,
        time: _this.timeLen,
      };
      if(_this.topicType == 2) {
        let word_data = [];
        _this.detail.show_text.forEach((item,index)=>{
          if(index >= _this.start_index && index <= _this.end_index && item != '&nbsp;') {
            word_data.push(item);
          }
        })
        params.word_data = word_data.join(' ');
      }
      if (localStorage.getItem("token")) {
        _this.$axios
          .post("exercise.topic/submit", params)
          .then((res) => {
            console.log(res);
            if (res.code == 1) {
              if(!res.data.content) {
                _this.result_loading = true;
                _this.waiting(res.data);
              }
              _this.topicExerciseId = res.data.topicExerciseId;
              _this.obj = res.data;
              _this.huida = true;
              _this.$message.success("答题已完成");
              _this.status = true;
              _this.loading = false;
            } else {
              console.log("当前的等待状态", _this.loading);
              _this.loading = false;
              _this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            _this.loading = false;
            console.log('err',err);
            if(err.msg) {
              _this.$message.error(err.msg);
            } else {
              _this.$message.error('请求超时');
            }
          });
      } else {
        _this.$message.error("您还没登录，请先登录");
      }
    },
    waiting(topic_exercise){
      waitingresult = setInterval(() => {
        let params = {
          topic_exercise_id: topic_exercise.topicExerciseId
        }
        _this.$axios
          .post("exercise.topic/getTopicExerciseResult", params)
          .then((res) => {
            if (res.code == 1) {
              if(res.data) {
                _this.obj = res.data;
                _this.result_loading = false;
                clearInterval(waitingresult);
              }
            }
          })
          .catch((err) => {
            _this.loading = false;
            _this.$message.error(err.msg);
          });
      }, 2000);
    },

    // 播放录音
    playAudio() {
      _this.classic.start();
    },

    // 上传音频
    submitUp() {
      let PCMBlob = _this.recorderData.WAV; //获取 PCM 数据
      console.log(PCMBlob);
      var formData = new FormData();
      formData.append("file", PCMBlob);
      var url = "common/uploadRecord";
      const loading = this.$loading({
        lock: true,
        text: '音频上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      _this.$uploadFile
        .post(url, formData)
        .then(function (res) {
          console.log(res);
          _this.auUrl = res.data.url;
          _this.auFullUrl = res.data.fullurl;
          loading.close();
          _this.submitTopic();
        })
        .catch((err) => {
          console.log('err',err);
          _this.$notify.error({
            title: "提示",
            message: "录音上传失败请重试",
            duration: 1500,
          });
        });
    },
    // 微信分享复制链接
    copyToH5(){
      let pinyin = 'RA';
      let tips = pinyin+" "+_this.detail.number+" "+" PTE易王炸AI评分 ";
      this.$copyText(tips+" "+window.location.origin+"/H5Detail?exericise_id="+_this.topicExerciseId+"&topic_id="+ _this.detail.id+"&pinyin="+pinyin+"&title="+encodeURIComponent(_this.detail.name)+"&page=questionType6");
      this.$message.success("地址复制成功")
    },
    //将秒转化为时分秒
    formateSeconds(endTime) {
      let secondTime = parseInt(endTime); //将传入的秒的值转化为Number
      let min = 0; // 初始化分
      let h = 0; // 初始化小时
      let result = "";
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        min = parseInt(secondTime / 60); //获取分钟，除以60取整数，得到整数分钟
        secondTime = parseInt(secondTime % 60); //获取秒数，秒数取佘，得到整数秒数
        if (min > 60) {
          //如果分钟大于60，将分钟转换成小时
          h = parseInt(min / 60); //获取小时，获取分钟除以60，得到整数小时
          min = parseInt(min % 60); //获取小时后取佘的分，获取分钟除以60取佘的分
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },

    // 获取详情
    getDetail() {
      var params = {
        topic_id: _this.id,
      };
      _this.$axios
        .post("exercise.topic/getTopicInfo", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.detail = res.data;
            if (res.data.extend.fast != "") {
              _this.fastMp3Url = _this.$global.qiniuUrl + res.data.extend.fast
            }
            if (res.data.extend.slow != "") {
              _this.slowMp3Url = _this.$global.qiniuUrl + res.data.extend.slow
            }
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取传递过来的参数
    getParams() {
      _this.id = _this.$route.query.id;
      _this.title = _this.$route.query.name;
      _this.type = _this.$route.query.type;
      _this.getDetail();
    },
  },
};
</script>
<style>
.icon {
  cursor: pointer;
}
.word_select_color {
  background: #f6704b;
}
.word_color {
  background: #f8a189;
}
.mp3_tool {
  width: 100%;
  height: auto;
  background: #ffffff;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px 0px;
  box-shadow: 0 3px 12px rgba(235, 208, 195, 1);
  padding-left: 10px;
}
.one {
  width: auto !important;
}
.fenshu {
  padding-left: 12px;
  padding-right: 12px;
}
.topic_type_answer_botbtn.now {
    margin-right: 20px;
}
.only_one {
  width: auto;
  padding: 0 15px;
}
.onecentence:hover {
  background-color: #f8a189;
}
</style>
