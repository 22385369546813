<template>
  <div class="page">
    <div class="goback" @click="goBack()">
      <img class="goback-img" src="@/assets/images/img.png" alt="">
    </div>
    <div class="pre">
      <div class="pinyin">{{ pinyin }}</div>
      <div class="pre-title">{{ title }}</div>
    </div>
    <div class="navtitle">
      <div class="navtitle-item" @click="toTopic()">
        <div :class="ischoose === 1?'current-choose title':'title'">
          作答
        </div>
      </div>
      <div class="navtitle-item"  @click="toChat">
        <div :class="ischoose === 2?'current-choose title':'title'">
          讨论
        </div>
      </div>
      <div class="navtitle-item" @click="toExercise">
        <div :class="ischoose === 3?'current-choose title':'title'">
          我的练习
        </div>
      </div>
      <div class="navtitle-item" @click="dialogFormVisible=true">
        <div class="title">
          意见反馈
        </div>
      </div>
    </div>
    <!-- Form -->
    <el-dialog title="问题反馈" :center="center" :visible.sync="dialogFormVisible">
    <el-form :model="form">
        <el-radio-group v-model="form.radio">
          <el-radio label="1">未及时更新</el-radio>
          <el-radio label="2">文本有错误</el-radio>
          <el-radio label="3">音频有错误</el-radio>
          <el-radio label="4">讨论区不良言论</el-radio>
          <el-radio label="5">其他</el-radio>
        </el-radio-group>
        <div style="margin-top:30px;">
          <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          placeholder="请输入内容"
          resize="none"
          v-model="form.textarea">
        </el-input>
        </div>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmit" size="small" plain>确 定</el-button>
    </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name:  'Navtitle',
  props: {
    topic_id: undefined,
    pinyin:   {
      type:    String,
      default: "ERROR"
    },
    title:    {
      type:    String,
      default: "发生错误啦"
    },
    ischoose: {
      type:    Number,
      default: 1
    },
    page: {
      type:    String,
      default: ""
    }
  },
  data() {
    return {
      center: true,
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        radio: '5',
        textarea: ''
      },
      formLabelWidth: '120px'
    }
  },
  mounted() {
    if (this.topic_id == undefined) {
      console.log('Navtitle组件的topic_id是必须传的！！！')
    }
  },
  methods: {
    toTopic(){
      if (this.page == ""){
        this.$message.error("发生错误,回不去了！");
        return
      }
      this.$router.push({
        path: "/"+this.page+"?id=" + this.topic_id+"&name="+this.title
      })

    },
    checkParam() {
      if (this.topic_id == undefined) {
        this.$message.error("当前做的是那道题啊！");
        return
      }
    },
    toExercise() {
      this.checkParam()
      this.$router.replace({
        path: "/myExercise?id=" + this.topic_id+"&pinyin="+this.pinyin+"&title="+this.title+"&page="+this.page
      })

    },
    toChat() {
      this.checkParam()
      this.$router.replace({
        path: "/myChat?id=" + this.topic_id+"&pinyin="+this.pinyin+"&title="+this.title+"&page="+this.page
      })

    },
    goBack() {
      this.$router.go(-1);
    },
    onSubmit() {
      console.log('submit!');
      var params = {
        topic_id: this.topic_id,
        radio: this.form.radio,
        textarea: this.form.textarea
      };
      this.$axios
        .post("exercise.topic/feedback", params)
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            this.$message.success(res.msg);
            this.dialogFormVisible = false;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.msg);
        });
    }
  }
};
</script>
<style scoped>
.page {
  display: flex;
  justify-content: space-between;

  background: rgba(249, 249, 249, 1);
  border-radius: 10px;
}

.goback {
  margin: 0px 20px;
  height: 44px;
  display: flex;
  align-items: center;
}

.goback-img {
  width: 24px;
  height: 24px;

}

.pre {
  display: flex;
  align-items: center;
}

.pinyin {
  height: 24px;
  padding: 0 10px;
  border-radius: 12px;
  margin-right: 20px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  background: rgba(242, 127, 109, 1);
  line-height: 24px;
}

.pre-title {
width: 120px;
  font-size: 16px;
  color: rgba(6, 18, 30, 1);
  font-weight: 500;
  margin-right: 20px;
}

.navtitle {
  display: flex;
  justify-content: space-around;
}

.navtitle-item {
  width: 140px;
  height: 44px;
  margin: auto 10px;
  display: flex;
}

.navtitle-item:hover  {
  cursor: pointer;
}
.goback-img:hover{
  cursor: pointer;

}

.title:hover {
  font-weight: 600;
  font-size: 18px;

}


.current-choose {
  font-weight: 600 !important;

}

.title {
  color: rgba(246, 112, 75, 1);
  width: 100%;
  height: 30px;
  margin: auto 0px;
  display: flex;
  justify-content: center;
  border-right: 1px solid rgb(189, 180, 178);
  font-size: 16px;
  font-weight: 100;


}

.navtitle-item:nth-child(4) .title {
  border-right: none;
}
.el-radio {
  margin-bottom: 10px !important;
}
</style>
